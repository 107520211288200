const Reviews = () => {
  return (
    <div id="reviews">
      <div className="reviews">Reviews</div>
      <div className="reviews-container">
        <div className="reviews-container-item">
          <div className="reviews-item-star-review">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
          <div className="reviews-item-purchase">Fade with beard</div>
          <div className="reviews-item-review">Grade A all the way around... real nice with those clippers!!!</div>
          <div className="reviews-item-name">Chad M</div>
          <div className="reviews-item-date">Oct 25, 2022</div>
        </div>
        <div className="reviews-container-item">
          <div className="reviews-item-star-review">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
          <div className="reviews-item-purchase">Fade with beard</div>
          <div className="reviews-item-review">Great experience</div>
          <div className="reviews-item-name">Michael I</div>
          <div className="reviews-item-date">Oct 21, 2022</div>
        </div>
        <div className="reviews-container-item">
          <div className="reviews-item-star-review">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
          <div className="reviews-item-purchase">Signature Fade</div>
          <div className="reviews-item-review">Great service and very professional.</div>
          <div className="reviews-item-name">Gerardo M</div>
          <div className="reviews-item-date">Oct 2, 2022</div>
        </div>
        <div className="reviews-container-item">
          <div className="reviews-item-star-review">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
          <div className="reviews-item-purchase">Signature Fade</div>
          <div className="reviews-item-review">Ryan is a great artist and offers a precision haircut. Very satisfied.</div>
          <div className="reviews-item-name">D C</div>
          <div className="reviews-item-date">Sep 16, 2022</div>
        </div>
        <div className="reviews-container-item">
          <div className="reviews-item-star-review">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
          <div className="reviews-item-purchase">Signature Fade</div>
          <div className="reviews-item-review">Excellent hair cuts</div>
          <div className="reviews-item-name">Jr. J</div>
          <div className="reviews-item-date">Sep 3, 2022</div>
        </div>
        <div className="reviews-container-item">
          <div className="reviews-item-star-review">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
          <div className="reviews-item-purchase">Fade with beard</div>
          <div className="reviews-item-review">🔥</div>
          <div className="reviews-item-name">Devon H</div>
          <div className="reviews-item-date">Jul 29, 2022</div>
        </div>
        <div className="reviews-container-item">
          <div className="reviews-item-star-review">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
          <div className="reviews-item-purchase">Fade with beard</div>
          <div className="reviews-item-review">Always on top of his game</div>
          <div className="reviews-item-name">Oscar F</div>
          <div className="reviews-item-date">Jul 25, 2022</div>
        </div>
        <div className="reviews-container-item">
          <div className="reviews-item-star-review">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
          <div className="reviews-item-purchase">Signature Fade</div>
          <div className="reviews-item-review">The best around for sure!</div>
          <div className="reviews-item-name">Jalen H</div>
          <div className="reviews-item-date">Jul 9, 2022</div>
        </div>
        <div className="reviews-container-item">
          <div className="reviews-item-star-review">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
          <div className="reviews-item-purchase">Fade with beard</div>
          <div className="reviews-item-review">First time getting my cut there, definitely found my new barber! 100% fire</div>
          <div className="reviews-item-name">Rayven R</div>
          <div className="reviews-item-date">Jun 17, 2022</div>
        </div>
        <div className="reviews-container-item">
          <div className="reviews-item-star-review">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
          <div className="reviews-item-purchase">Fade with beard</div>
          <div className="reviews-item-review">If you need your haircut 10/10 recommend going to ryan. bro knows how to cut</div>
          <div className="reviews-item-name">Jeffery w</div>
          <div className="reviews-item-date">Jun 7, 2022</div>
        </div>
      </div>
    </div>
  )
}

export default Reviews;