import image1 from '../resources/1.jpg'
import image2 from '../resources/2.jpg'
import image3 from '../resources/3.jpg'
import image4 from '../resources/4.jpg'
import image5 from '../resources/5.jpg'
import image6 from '../resources/6.jpg'
import image7 from '../resources/7.jpg'
import image8 from '../resources/8.jpg'

const ImageGallery = () => {
  return (
    <div className="images">
      <div className="div1">
    <img src={`${image1}`} className="img1" alt="Signature Fade"></img>
    </div>
      <div className="div2">
    <img src={`${image2}`} className="img2" alt="Signature Fade"></img>
    </div>
      <div className="div3">
    <img src={`${image3}`} className="img3" alt="Signature Fade"></img>
    </div>
      <div className="div4">
    <img src={`${image4}`} className="img4" alt="Signature Fade"></img>
    </div>
      <div className="div5">
    <img src={`${image5}`} className="img5" alt="Signature Fade"></img>
    </div>
      <div className="div6">
    <img src={`${image6}`} className="img6" alt="Signature Fade"></img>
    </div>
      <div className="div7">
    <img src={`${image7}`} className="img7" alt="Signature Fade"></img>
    </div>
      <div className="div8">
    <img src={`${image8}`} className="img8" alt="Signature Fade"></img>
    </div>
    </div>
  )
}

export default ImageGallery;