const Hours = () => {
  return (
    <div id="hours">
      <div className="hours">Hours</div>
      <div className="hours-info">
      <div className="time-available">8 A.M. - 7 P.M. Tuesday-Saturday</div>
      <div className="time-available">Appointment through bookings only!</div>
      </div>
      </div>
  )
}

export default Hours;