import './App.css';
import NavBar from './components/NavBar'
import ImageGallery from './components/ImageGallery'
import Services from './components/Services'
import Reviews from './components/Reviews'
import Hours from './components/Hours'
import Location from './components/Location'
import Contact from './components/Contact'

function App() {
  return (
    <div className="App">
      <NavBar />
      <ImageGallery />
      <Services />
      <Reviews />
      <Location />
      <Hours />
      <Contact />
    </div>
  );
}

export default App;
