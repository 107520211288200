const Location = () => {
  return (
    <div id="location">
      <div className="location">Location</div>

      <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d807.0779305389697!2d-89.05837123144089!3d42.31836114884334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8808bdd300d84aa5%3A0xd58c2481b6982674!2s6112%20N%202nd%20St%2C%20Loves%20Park%2C%20IL%2061111!5e0!3m2!1sen!2sus!4v1684890978957!5m2!1sen!2sus" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <a href="http://maps.apple.com/?address=1,6112+n+2nd+st,Rockford,Illinois" className="address-map">6112 N 2ND ST, LOVES PARK, IL 61111</a>
    </div>
  )
}

export default Location;

