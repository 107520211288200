const Services = () => {
  const url = "https://booksy.com/en-us/107293_rockstar-cutz_barber-shop_19332_loves-park"
  return (
    <div id="services">
      <div className="services">Services</div>
      <div className="services-container">
        <a href={url} target="_blank" rel="noreferrer" className="booking-link">
        <div className="service-item">
          <div className="name-description">
          <div className="service-item-name">Signature Fade</div>
          <div className="service-item-description">Get a haircut with a razor line-up.</div>
          </div>
          <div className="service-item-price">$35+</div>
          <div className="service-item-time">~45min</div>
        </div>
        </a>
        <a href={url} target="_blank" rel="noreferrer" className="booking-link">
        <div className="service-item">
          <div className="name-description">
          <div className="service-item-name">Fade & Beard Trim</div>
          <div className="service-item-description">Get a signature fade and get your beard trimmed.</div>
          </div>
          <div className="service-item-price">$35+</div>
          <div className="service-item-time">~45min</div>
        </div>
        </a>
        <a href={url} target="_blank" rel="noreferrer" className="booking-link">
        <div className="service-item">
          <div className="name-description">
          <div className="service-item-name">Kids Haircut<div className="disclosure">{'(Kids 9 and under)'}</div></div>
          <div className="service-item-description">Bring your child in for a haircut!</div>
          </div>
          <div className="service-item-price">$30+</div>
          <div className="service-item-time">~35min</div>
        </div>
        </a>
        <a href={url} target="_blank" className="booking-link" rel="noreferrer">
        <div className="service-item">
          <div className="name-description">
          <div className="service-item-name">Perm <div className="disclosure">{'(Haircut Not Included!)'}</div></div>
          <div className="service-item-description">Come in with straight hair and leave with curly hair.</div>
          </div>
          <div className="service-item-price">$80</div>
          <div className="service-item-time">~2h</div>
        </div>
        </a>
        <a href={url} target="_blank" className="booking-link" rel="noreferrer">
        <div className="service-item">
          <div className="name-description">
          <div className="service-item-name">Perm and Haircut</div>
          <div className="service-item-description">Get a perm and a haircut.</div>
          </div>
          <div className="service-item-price">$105</div>
          <div className="service-item-time">~3h</div>
        </div>
        </a>
        <a href={url} target="_blank" className="booking-link" rel="noreferrer">
        <div className="service-item">
          <div className="name-description">
          <div className="service-item-name">Beard Trim</div>
          <div className="service-item-description">Get your beard trimmed.</div>
          </div>
          <div className="service-item-price">$25</div>
          <div className="service-item-time">~30min</div>
        </div>
        </a>
      </div>
    </div>
  )
}

export default Services;