import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

const NavBar = () => {
  const booking = "https://booksy.com/en-us/107293_rockstar-cutz_barber-shop_19332_loves-park";

  return (
    <div className="navbar">
      <div id="title">Rockstar Cutz & Kickz</div>
      <div className="navbar-links">
        <div className="insta-nav">
          <a href="https://www.instagram.com/rockstar_cutz/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        <div className="booking-nav">
          <a href={booking} target="_blank" rel="noreferrer">Book Now</a>
        </div>
        <div className="services-nav">
          <a href="#services">Services</a>
        </div>
        <div className="reviews-nav">
          <a href="#reviews">Reviews</a>
        </div>
        <div className="location-nav">
          <a href="#location">Location</a>
        </div>
        <div className="hours-nav">
          <a href="#hours">Hours</a>
        </div>
      </div>
    </div>
  )
}

export default NavBar;