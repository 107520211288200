import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

const Contact = () => {
  return (
    <div className="socials">
      <div className="insta">
        <a href="https://www.instagram.com/rockstar_cutz/" target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faInstagram} />
      </a>
      </div>
      <a href="tel:+1-815-914-1966">
      <div className="phone">
      <FontAwesomeIcon icon={faPhone}/><div>(815) 914-1966</div>
      </div>
      </a>
    </div>
  )
}

export default Contact;